import { addDays, subDays } from 'date-fns';

export const daysOfWeek = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const generateDays = (
	direction: 'prev' | 'next',
	currentState: Date[],
	count: number = 24,
): { newDays: Date[]; addedAtStart: number } => {
	let newDates: Date[] = new Array(count);
	let addedAtStart = 0;

	if (direction === 'prev') {
		const startDate = subDays(currentState[0], count);
		for (let i = 0; i < count; i++) {
			newDates[i] = addDays(startDate, i);
		}
		addedAtStart = count;
		return { newDays: newDates.concat(currentState), addedAtStart };
	} else {
		const startDate = addDays(currentState[currentState.length - 1], 1);
		for (let i = 0; i < count; i++) {
			newDates[i] = addDays(startDate, i);
		}
		return { newDays: currentState.concat(newDates), addedAtStart };
	}
};

export const getSelectedDaysInActualOrder = (
	selectedDays: string[],
): string[] => {
	return daysOfWeek.filter((day) => selectedDays.includes(day));
};
