import Button from '../../../../components/ui/button';
import Modal, { ModalProps } from '../../../../components/ui/modal';
import { iconColors } from '../../../../constants/style';
import { ChangeEvent, useEffect, useState } from 'react';
import Input from '../../../../components/ui/input/Input';
import { useFormik } from 'formik';
import { daysOfWeek } from '../../lib/days';
import WeekdaysPreview from '../dynamic-calendar/WeekdaysPreview';
import WeekdayToggle from '../dynamic-calendar/WeekdayToggle';
import { faker } from '@faker-js/faker';
import useColor from '../../../../lib/hooks/useColor';
import useCalendars from '../../../../lib/hooks/useCalendars';
import { generateUUID } from '../../../../lib/functions';
import DateRange from '../dynamic-calendar/DateRange';
import { SnackColor } from '../../../../context/CalendarsContext';

const CreateCustomCalendarModal = (props: ModalProps) => {
	const [selectedDays, setSelectedDays] = useState(daysOfWeek);
	const {
		helpers: { addCalendar },
	} = useCalendars();

	const form = useFormik({
		initialValues: {
			name: faker.animal.dog(),
			hasDateRange: false,
			selectedDays,
			color: 'accent' as SnackColor,
			startDate: new Date(),
			endDate: new Date(),
		},
		onSubmit: (values) => {
			addCalendar({
				id: generateUUID(),
				name: values.name,
				hasDateRange: values.hasDateRange,
				selectedDays: values.selectedDays,
				color: values.color,
				minDate: values.startDate,
				maxDate: values.endDate,
				createdAt: new Date(),
				type: 'dynamic',
			});
			props.onClose();
		},
	});

	const color = useColor(iconColors[form.values.color]);

	const onSetFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		form.setFieldValue(name, value);
	};

	const toggleSelectedDay = (day: string) => {
		const newSelectedDays = selectedDays.includes(day)
			? selectedDays.filter((d) => d !== day)
			: [...selectedDays, day];
		setSelectedDays(newSelectedDays);
	};

	const isSelected = (day: string) => selectedDays.includes(day);

	useEffect(() => {
		if (props.isOpen) {
			form.setValues({
				name: faker.animal.rodent(),
				hasDateRange: false,
				selectedDays,
				color: 'accent',
				startDate: new Date(),
				endDate: new Date(),
			});
		}
	}, [props.isOpen]);

	useEffect(() => {
		form.setFieldValue('selectedDays', selectedDays);
	}, [selectedDays]);

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			size="xl"
			noPadding={props.noPadding}>
			<div className="flex flex-col w-full gap-4">
				<div className="flex w-full gap-4">
					<form
						onSubmit={form.handleSubmit}
						className="w-[360px] flex flex-col gap-10 p-4">
						<div>
							<p className="text-zinc-950 text-xl dark:text-white font-semibold">
								Dynamic calendars
							</p>
							<p className="text-zinc-500 dark:text-zinc-400">
								Create a custom calendar with your own days
							</p>
						</div>
						<div className="space-y-2">
							<label
								htmlFor="calendar-name"
								className="font-semibold">
								Calendar name
							</label>
							<div className="flex gap-2 items-center bg-zinc-100 focus-within:bg-zinc-400/30 hover:bg-zinc-200 p-1.5 rounded-xl">
								Color
								<Input
									autoFocus
									id="calendar-name"
									type="text"
									name="name"
									className="bg-transparent focus:bg-transparent px-2 font-semibold"
									value={form.values.name}
									onChange={onSetFieldValue}
								/>
							</div>
						</div>
						<DateRange
							isEnabled={form.values.hasDateRange}
							toggleIsEnabled={() =>
								form.setFieldValue('hasDateRange', !form.values.hasDateRange)
							}
						/>
						<div className="space-y-4">
							<div>
								<h3 className="font-semibold py-1">
									Week days ({selectedDays.length} selected)
								</h3>
								<p className="text-zinc-500">
									Select the days you want to be included in your calendar
								</p>
							</div>
							<div className="flex flex-col gap-1 p-2 rounded-xl bg-zinc-100">
								{daysOfWeek.map((day) => (
									<WeekdayToggle
										key={day}
										day={day}
										isSelected={isSelected(day)}
										toggleSelectDay={toggleSelectedDay}
										color={color}
									/>
								))}
							</div>
						</div>
						<div className="flex w-full justify-end mt-8 gap-4">
							<Button onClick={props.onClose}>Cancel</Button>
							<Button
								type="submit"
								style={{ background: color, color: '#fff' }}
								className="py-2 px-4 font-semibold">
								Save calendar
							</Button>
						</div>
					</form>
					<WeekdaysPreview selectedDays={selectedDays} />
				</div>
			</div>
		</Modal>
	);
};

export default CreateCustomCalendarModal;
