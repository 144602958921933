import {
	add,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	format,
	parse,
	startOfMonth,
	startOfWeek,
} from 'date-fns';
import { useMemo, useState } from 'react';
import { useAppDispatch } from '../../redux/store';

const useCalendarDates = (today: Date, minDate?: Date, maxDate?: Date) => {
	const [selectedDate, setSelectedDate] = useState(today);
	const dispatch = useAppDispatch();

	const [currentMonth, setCurrentMonth] = useState<string>(
		format(selectedDate, 'MMMM yyyy'),
	);

	const firstDayOfCurrentMonth = parse(currentMonth, 'MMMM yyyy', new Date());

	const month = useMemo(
		() =>
			eachDayOfInterval({
				start: startOfWeek(startOfMonth(firstDayOfCurrentMonth)),
				end: endOfWeek(endOfMonth(firstDayOfCurrentMonth)),
			}),
		[firstDayOfCurrentMonth],
	);

	const week = useMemo(
		() =>
			eachDayOfInterval({
				start: startOfWeek(selectedDate),
				end: endOfWeek(selectedDate),
			}),
		[selectedDate],
	);

	const selectDate = (day: Date) => {
		if (day < minDate || day > maxDate) return;
		setSelectedDate(day);
	};

	const nextDate = () => {
		const nextDay = add(selectedDate, { days: 1 });
		if (nextDay > maxDate) return;
		selectDate(nextDay);
	};

	const prevDate = () => {
		const prevDay = add(selectedDate, { days: -1 });
		if (prevDay < minDate) return;
		selectDate(prevDay);
	};

	const nextWeek = () => {
		const newDate = add(selectedDate, { weeks: 1 });
		if (newDate > maxDate) return;
		selectDate(newDate);
	};

	const prevWeek = () => {
		const newDate = add(selectedDate, { weeks: -1 });
		if (newDate < minDate) return;
		selectDate(newDate);
	};

	const nextMonth = () => {
		const firstDayNextMonth = add(firstDayOfCurrentMonth, { months: 1 });
		if (firstDayNextMonth > maxDate) return;
		setCurrentMonth(format(firstDayNextMonth, 'MMMM yyyy'));
	};

	const prevMonth = () => {
		const firstDayPrevMonth = add(firstDayOfCurrentMonth, { months: -1 });
		if (firstDayPrevMonth < minDate) return;
		setCurrentMonth(format(firstDayPrevMonth, 'MMMM yyyy'));
	};

	return {
		selectDate,
		selectedDate,
		week,
		month,
		nextDate,
		prevDate,
		nextWeek,
		prevWeek,
		nextMonth,
		prevMonth,
		firstDayOfCurrentMonth,
	};
};

export default useCalendarDates;
