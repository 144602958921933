import useCalendarDates from '../../lib/hooks/useCalendarDates';
import {
	addDays,
	eachHourOfInterval,
	endOfToday,
	endOfWeek,
	isEqual,
	isSameDay,
	startOfToday,
	startOfWeek,
} from 'date-fns';
import { useAppDispatch } from '../../redux/store';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import Timezone from './components/time/Timezone';
import PlannerHeader from './components/header';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { cn } from '../../lib/functions/utils';
import { generateDays } from './lib/days';
import useCalendarViewEffects from './hooks/useCalendarViewEffects';
import Day from './components/day';
import DailyPlannerDNDContext from './components/dragndrop/context';
import TimezonesHeader from './components/time/TimezonesHeader';
import AllDayEvent from './components/time/AllDayHeader';
import TimestampTime from './components/time/TimestampTime';
import { SnackEvent, SnackEventPriority } from '../../redux/events/types';
import { iconColors } from '../../constants/style';

const generateTimeIntervals = () => {
	const timeIntervals = eachHourOfInterval(
		{
			start: startOfToday(),
			end: endOfToday(),
		},
		{
			step: 1,
		},
	);

	return timeIntervals;
};

const useGoogleCalendarEvents = () => {
	const [events, setEvents] = useState<SnackEvent[]>([]);
	const getEventsForDay = (day: Date) => {
		return events.filter(
			(event) =>
				isSameDay(day, event.startTime) || isSameDay(day, event.endTime),
		);
	};

	return { events, getEventsForDay };
};

const DailyPlanner = () => {
	const dispatch = useAppDispatch();
	const { selectDate, selectedDate } = useCalendarDates(startOfToday());
	const {
		fixedListOuterRef,
		fixedList,
		timestampPosition,
		timezoneColumn,
		days,
		gotoToday,
		setDays,
		setTimezoneColumnRef,
		setFixedListRef,
	} = useCalendarViewEffects(selectedDate);

	const [numberOfDaysShown, setNumberOfDaysShown] = useState(7);
	const [visibleStartIndex, setVisibleStartIndex] = useState(0);
	const [visibleEndIndex, setVisibleEndIndex] = useState(0);
	const itemWidth = 180 * ((1 * 7) / numberOfDaysShown);

	const timezonesCount = 1;

	const timeIntervals = generateTimeIntervals();

	const nextWeek = () => {
		const end = endOfWeek(days[visibleEndIndex]);
		const startOfNextWeek = startOfWeek(addDays(end, 1));
		const indexOfStartOfNextWeek = days.findIndex((day) =>
			isEqual(day, startOfNextWeek),
		);

		fixedList.scrollToItem(indexOfStartOfNextWeek, 'center');
	};

	const prevWeek = () => {
		const start = startOfWeek(days[visibleStartIndex]);
		const endOfPrevWeek = endOfWeek(addDays(start, -1));
		const indexOfEndOfPrevWeek = days.findIndex((day) =>
			isEqual(day, endOfPrevWeek),
		);
		fixedList.scrollToItem(indexOfEndOfPrevWeek, 'center');
	};

	const onItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
		const threshold = 5; // Number of items from the start/end to trigger loading
		const loadCount = 24; // Number of days to load

		setVisibleStartIndex(visibleStartIndex);
		setVisibleEndIndex(visibleStopIndex);

		if (visibleStartIndex <= threshold) {
			// Load more items at the start
			const { newDays, addedAtStart } = generateDays('prev', days, loadCount);
			setDays(newDays);
			// Adjust scroll position after adding new items at the start
			if (fixedList) {
				fixedList.scrollToItem(visibleStartIndex + addedAtStart, 'start');
			}
		} else if (visibleStopIndex >= days.length - threshold) {
			// Load more items at the end
			const { newDays } = generateDays('next', days, loadCount);
			setDays(newDays);
		}
	};

	const updateEvent = (event) => {};
	const createEvent = (event) => {};

	const { events, getEventsForDay } = useGoogleCalendarEvents();

	return (
		<div
			id="calendar"
			className={
				'h-full overflow-clip gap-2 flex flex-col bg-white dark:bg-[#121212]'
			}>
			<PlannerHeader
				date={days[visibleStartIndex]}
				nextDate={nextWeek}
				prevDate={prevWeek}
				gotoToday={gotoToday}
			/>
			<AutoSizer
				style={{ height: 'calc(2080px + 2.5rem)', width: '100%' }}
				className="h-full flex flex-1 w-full -mt-1 top-0 overflow-y-auto">
				{({ height, width }) => (
					// Using width of 1 item * total number of days results in the FixedList rendering all items ahead of itself
					// This will result in a huge perfomance hit, hence the use of Width from Autosizer
					// Also, to maintain stickyness for the timezone bar, we synchronize the scroll using outerRef on the FixedList
					<>
						<div
							ref={setTimezoneColumnRef}
							style={{
								width: `calc(8rem * ${timezonesCount})`,
								height,
							}}
							className={cn(
								'z-30  left-0 top-0 overflow-y-auto flex-shrink-0 flex flex-col relative',
							)}>
							<div className="sticky top-0 z-30 flex flex-col bg-white dark:bg-[#121212]">
								<TimezonesHeader timezones={['Africa/Johannesburg']} />
								<AllDayEvent />
							</div>
							<div
								style={{
									gridTemplateColumns: `repeat(${timezonesCount}, 1fr)`,
								}}
								className="grid relative">
								{['Africa/Johannesburg'].map((zone, idx) => (
									<Timezone
										key={`timezone-${zone}`}
										zone={zone}
										timeIntervals={timeIntervals}
										minimal={false}
										noBorder={idx !== timezonesCount - 1}
									/>
								))}
								<TimestampTime top={timestampPosition} />
							</div>
						</div>
						<DailyPlannerDNDContext
							daysContainerRef={fixedListOuterRef}
							updateEvent={updateEvent}>
							<List
								ref={setFixedListRef}
								outerRef={fixedListOuterRef}
								overscanCount={2 * numberOfDaysShown}
								innerElementType={innerElementType}
								height={height}
								itemCount={days.length}
								itemSize={itemWidth}
								layout="horizontal"
								style={{
									// scrollSnapType: 'x mandatory',
									// scrollSnapAlign: 'start',
									overflowX: 'auto',
									willChange: 'transform',
								}}
								width={width - 8 * 13 * timezonesCount}
								onItemsRendered={onItemsRendered}
								itemData={{
									days,
									width: itemWidth,
									selectDate,
									selectedDate,
									dispatch,
									timestampPosition,
									events: (day: Date) => getEventsForDay(day),
								}} // Pass `days` as data to your item renderer
							>
								{Day}
							</List>
						</DailyPlannerDNDContext>
					</>
				)}
			</AutoSizer>
		</div>
	);
};

const innerElementType = forwardRef(
	({ style, ...rest }: any, ref: ForwardedRef<HTMLDivElement>) => (
		<div
			ref={ref}
			style={{
				...style,
				// overscrollBehavior: 'contain',
			}}
			{...rest}
		/>
	),
);

export default DailyPlanner;
