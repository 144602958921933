import { createSlice } from '@reduxjs/toolkit';
import { SnackEvent } from './types';
import _ from 'lodash';
import { isEqual, isSameDay } from 'date-fns';
import { RootState } from '../store';

const initialState: {
	items: SnackEvent[];
} = {
	items: [],
};

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		addEvent: (state, action) => {
			state.items.push(action.payload);
		},
		deleteEvent: (state, action) => {
			state.items = state.items.filter((event) => event.id !== action.payload);
		},
		updateEvent: (state, action) => {
			const {
				id,
				startTime,
				endTime,
				priority,
				color,
				allDay,
				link,
				reminder,
			} = action.payload as SnackEvent;

			const existingEvent = state.items.find((event) => event.id === id);
			if (existingEvent) {
				existingEvent.startTime = startTime;
				existingEvent.endTime = endTime;
				existingEvent.priority = priority;
				existingEvent.reminder = reminder;
				existingEvent.color = color;
				existingEvent.allDay = allDay;
				existingEvent.link = link;
				existingEvent.lastUpdated = new Date();
			}
		},
		rescheduleEvent: (state, action) => {
			const { id, startTime, endTime } = action.payload;
			const existingEvent = state.items.find((event) => event.id === id);
			if (existingEvent) {
				existingEvent.startTime = startTime;
				existingEvent.endTime = endTime;
				existingEvent.lastUpdated = new Date();
			}
		},
		detatchEvent: (state, action) => {
			const { id } = action.payload;
			const existingEvent = state.items.find((event) => event.id === id);
			if (existingEvent) {
				existingEvent.taskId = null;
				existingEvent.lastUpdated = new Date();
			}
		},
	},
});

export const {
	addEvent,
	deleteEvent,
	updateEvent,
	rescheduleEvent,
	detatchEvent,
} = eventsSlice.actions;

export default eventsSlice.reducer;

/** Selectors */
export const selectEvents = (state: RootState) =>
	_.sortBy(state.events.items, (event) => event.startTime).reverse();

export const selectEventById = (id: string) => (state: RootState) =>
	state.events.items.find((event) => event.id === id);

export const selectEventByTaskId = (taskId: string) => (state: RootState) => {
	const event = state.events.items.find((event) => event.taskId === taskId);
	return event;
};

export const selectAllDayEvents = (state: {
	events: { items: SnackEvent[] };
}) =>
	_.sortBy(
		state.events.items.filter((event) => event.allDay),
		(event) => event.startTime,
	).reverse();

export const selectAllDayEventsByDate = (date: Date) => (state: RootState) =>
	_.sortBy(
		state.events.items.filter(
			(event) => isEqual(event.startTime, date) && event.allDay,
		),
		(event) => event.startTime,
	).reverse();

export const selectEventsByDate = (date: Date) => (state: RootState) => {
	const events = state.events.items.filter((event) => {
		return (
			isSameDay(new Date(event.startTime), date) ||
			isSameDay(new Date(event.endTime), date)
		);
	});

	return _.sortBy(events, (event) => event.startTime).reverse();
};

export const selectEventsByDateRange = (
	state: RootState,
	startDate: Date,
	endDate: Date,
) =>
	_.sortBy(
		state.events.items.filter(
			(event) =>
				(event.startTime >= startDate && event.startTime <= endDate) ||
				(event.endTime >= startDate && event.endTime <= endDate),
		),
		(event) => event.startTime,
	).reverse();
