import clsx from 'clsx';
import { CalendarDayTimeProps } from '../../types';
import { cn } from '../../../../lib/functions/utils';

const getTimeString = (time: Date, zone?: string) =>
	Intl.DateTimeFormat('en-US', {
		hour: 'numeric',
		hourCycle: 'h23',
		timeZone: zone,
		minute: '2-digit',
	})
		.format(time)
		.toLowerCase();

const Timezone = (
	props: { zone: string; noBorder?: boolean } & CalendarDayTimeProps,
) => {
	return (
		<div
			className={cn(
				'sticky left-0 flex-none px-2 border-zinc-400/20',
				'grid col-start-1 col-end-2 row-start-1',
				!props.noBorder && 'border-r',
			)}
			style={{ gridTemplateRows: `repeat(48, minmax(4rem))` }}>
			{props.timeIntervals.map((time, idx) => (
				<>
					<div
						className={'grid-item'}
						key={`time-${idx}`}>
						<div className="sticky left-0 -mt-2.5 text-number font-mono w-full text-right text-sm leading-5 text-zinc-400 dark:text-zinc-500">
							{idx === 0 ? '' : getTimeString(time, props.zone)}
						</div>
					</div>
				</>
			))}
			<div className={'grid-item'}>
				<div className="sticky left-0 -mt-2.5"></div>
			</div>
			<div className={'grid-item'}>
				<div className="sticky left-0 -mt-2.5"></div>
			</div>
		</div>
	);
};

export default Timezone;
