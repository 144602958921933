import { memo, useEffect, useMemo, useState } from 'react';
import { useSFSymbol } from '../../lib/core/sfsymbol';
import { motion } from 'framer-motion';
import { cn } from '../../lib/functions/utils';
import { iconColors, iconColorsWithDark } from '../../constants/style';
import path from 'path';

function SFSymbol({
	name,
	...rest
}: {
	name: string;
	className?: string;
	color?: string;
	height?: string | number;
	width?: string | number;
	onClick?: (e) => void;
}) {
	const [isInDarkMode, setIsDarkMode] = useState(false);

	useEffect(() => {
		const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

		const handleChange = () => {
			setIsDarkMode(darkModeQuery.matches);
		};

		darkModeQuery.addEventListener('change', handleChange);

		// Initial check
		handleChange();

		return () => darkModeQuery.removeEventListener('change', handleChange);
	}, []);
	const color = !!rest.color ? rest.color : iconColors.labelTertiary;
	// find the key which has the color
	const keyOfColor = Object.keys(iconColorsWithDark).find(
		(key) => iconColorsWithDark[key].light === color,
	);
	const colorWithDark = useMemo(() => {
		if (!keyOfColor) return color;
		if (isInDarkMode) {
			return iconColorsWithDark[keyOfColor].dark;
		}
		return iconColorsWithDark[keyOfColor].light;
	}, [keyOfColor, isInDarkMode]);
	const iconSrc = useSFSymbol(name, colorWithDark);

	return (
		<motion.img
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{ duration: 0.05, type: 'spring', ease: 'easeIn' }}
			onLoad={(e) => {
				e.currentTarget.style.opacity = '1';
			}}
			loading="lazy"
			crossOrigin="anonymous"
			referrerPolicy="no-referrer"
			decoding="async"
			draggable={false}
			onError={(e) => {
				e.currentTarget.src = iconSrc;
				e.currentTarget.onerror = null; // prevent infinite loop.
			}}
			src={iconSrc}
			alt={iconSrc ? name : ''}
			{...rest}
			className={cn('w-6 h-6', rest.className)}
		/>
	);
}

export default memo(SFSymbol);
