import { motion } from 'framer-motion';
import { iconColors } from '../../../../constants/style';
import { cn } from '../../../../lib/functions/utils';
import { SnackColor } from '../../../../context/CalendarsContext';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

const WeekdayToggle = (props: {
	day: string;
	isSelected: boolean;
	toggleSelectDay: (day: string) => void;
	color: SnackColor;
}) => {
	const onSelect = () => props.toggleSelectDay(props.day);
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.9 }}
			animate={{
				opacity: 1,
				scale: 1,
				background: props.isSelected ? props.color : 'transparent',
				color: props.isSelected ? '#fff' : iconColors.gray,
				fontWeight: props.isSelected ? '600' : '600',
			}}
			onClick={onSelect}
			className={cn(
				'flex justify-between items-center py-2 px-4 hover:bg-zinc-400/20 rounded-lg',
				props.isSelected && 'hover:bg-none font-semibold',
			)}>
			<p className="capitalize">{props.day}</p>
			<CheckBadgeIcon
				className="w-6 h-6"
				color={props.isSelected ? '#fff' : iconColors.gray}
			/>
		</motion.div>
	);
};

export default WeekdayToggle;
