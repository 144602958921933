import { Trigger } from '@radix-ui/react-dropdown-menu';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import Button from '../../../../components/ui/button';
import Dropdown from '../../../../components/ui/dropdown-menu';
import { iconColors } from '../../../../constants/style';
import useToggle from '../../../../lib/hooks/useToggle';
import CreateCustomCalendarModal from '../modals/DynamicCalendar';
import useCalendars from '../../../../lib/hooks/useCalendars';
import useColor from '../../../../lib/hooks/useColor';
import calendar from '../../../../redux/calendar';
import { Calendar } from '../../../../context/CalendarsContext';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';

const UserCalendarsDropdown = () => {
	const [isCreateCalendarModalOpen, toggleIsCreateCalendarModalOpen] =
		useToggle(false);
	const {
		calendars,
		helpers,
		selectedCalendars: globalSelectedCalendars,
	} = useCalendars();
	const [selectedCalendars, setSelectedCalendars] = useState<string[]>(
		globalSelectedCalendars,
	);
	const [allCalendarsSelected, toggleAllCalendarsSelected] = useToggle(false);

	const toggleSelectedCalendar = (
		e: MouseEvent | MouseEvent<HTMLInputElement>,
		calendar: Calendar,
	) => {
		e.stopPropagation();
		e.preventDefault();

		if (selectedCalendars.includes(calendar.id)) {
			if (selectedCalendars.length === 1) return;
			setSelectedCalendars(selectedCalendars.filter((c) => c !== calendar.id));
		} else {
			setSelectedCalendars([...selectedCalendars, calendar.id]);
		}
	};

	const toggleSelectAllCalendars = (
		e: MouseEvent | MouseEvent<HTMLInputElement>,
	) => {
		e.stopPropagation();
		e.preventDefault();
		toggleAllCalendarsSelected();
	};

	const isAllCalendarsSelected = useMemo(() => {
		return (
			selectedCalendars.length === calendars.length || allCalendarsSelected
		);
	}, [calendars, selectedCalendars, allCalendarsSelected]);

	useEffect(() => {
		if (isAllCalendarsSelected) {
			helpers.onUpdateSelectedCalendars(calendars.map((c) => c.id));
		}

		if (selectedCalendars.length === 0) return;

		if (!isAllCalendarsSelected) {
			helpers.onUpdateSelectedCalendars(selectedCalendars);
		}
	}, [selectedCalendars, isAllCalendarsSelected]);

	return (
		<Dropdown>
			<CreateCustomCalendarModal
				isOpen={isCreateCalendarModalOpen}
				onClose={toggleIsCreateCalendarModalOpen}
			/>

			<Trigger asChild>
				<Button className="text-zinc-950 dark:text-white gap-4 h-9 group border border-zinc-400/30">
					<p className="flex items-center -space-x-3">
						{calendars
							.filter((cal) => selectedCalendars.includes(cal.id))
							.slice(0, 3)
							.map((calendar) => (
								<span
									key={calendar.id}
									className="w-6 h-6 rounded-xl border-2 dark:border-zinc-400/20 dark:border-zinc-900 border-white"
									style={{
										backgroundColor:
											iconColors[calendar.color] ?? calendar.color,
									}}
								/>
							))}
					</p>
					{selectedCalendars.length > 1
						? `${selectedCalendars.length} calendars`
						: calendars.find((c) => c.id === selectedCalendars[0])?.name}
					<ChevronDownIcon className="w-5 h-5" />
				</Button>
			</Trigger>
			<Dropdown.Content>
				<CalendarItem
					key={'all-calendars-dropdown-item'}
					name="All calendars"
					color={iconColors.primary}
					createdAt={new Date()}
					hasDateRange={false}
					id={crypto.randomUUID()}
					type="static"
					selected={isAllCalendarsSelected}
					onClick={toggleSelectAllCalendars}
				/>

				<Dropdown.Separator />
				{calendars.map((calendar) => (
					<CalendarItem
						key={calendar.id}
						{...calendar}
						selected={
							!!selectedCalendars.find((cal) => calendar.id === cal) ||
							allCalendarsSelected
						}
						onClick={toggleSelectedCalendar}
					/>
				))}
				<Dropdown.Separator />

				<Dropdown.Item onClick={toggleIsCreateCalendarModalOpen}>
					<CalendarIcon className="w-5 h-5" />
					Add a dynamic calendar
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown>
	);
};

const CalendarItem = (
	props: Calendar & {
		selected?: boolean;
		onClick: (
			e: MouseEvent | MouseEvent<HTMLInputElement>,
			calendar: Calendar,
		) => void;
	},
) => {
	const color = useColor(iconColors[props.color] ?? props.color);

	const handleClick = (e: MouseEvent | MouseEvent<HTMLInputElement>) => {
		props.onClick(e, props);
	};

	return (
		<Dropdown.Item onClick={handleClick}>
			<p
				className="w-6 h-4 rounded-md"
				style={{ backgroundColor: color }}
			/>
			<p className="flex-1 mr-4">{props.name}</p>
			<label>
				<input
					className="pointer-events-none"
					type="checkbox"
					checked={props.selected}
					onChange={(e) => handleClick(e as unknown as MouseEvent)}
				/>
			</label>
		</Dropdown.Item>
	);
};

export default UserCalendarsDropdown;
