import * as Switch from '@radix-ui/react-switch';
import { CSSProperties, ReactNode } from 'react';
import { cn } from '../../../lib/functions/utils';
import useColor from '../../../lib/hooks/useColor';
import { iconColors } from '../../../constants/style';

export default function Toggle(props: {
	id?: string;
	className?: string;
	isChecked?: boolean;
	onChange?: (val: boolean) => void;
	label?: string;
}) {
	const accent = useColor(iconColors.accent);
	const dataChecked = `data-[state=checked]:bg-surface-12 dark:data-[state=checked]:bg-surface-1`;
	return (
		<div className={'flex items-center gap-3 group'}>
			<Switch.Root
				checked={props.isChecked}
				onCheckedChange={props.onChange}
				className={cn(
					'w-[21px] h-[14px] bg-surface-8 dark:bg-surface-10 rounded-full relative',
					'focus:shadow outline-none cursor-default',
					dataChecked,
				)}
				id={props.id}
				style={{ '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)' } as any}>
				<Switch.Thumb className="w-[9px] h-[9px] bg-white dark:bg-surface-12 rounded-full shadow transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[5px]" />
			</Switch.Root>
			{props.label && (
				<label
					className="group-hover:text-surface-12 dark:group-hover:text-white dark:text-surface-6 font-medium"
					htmlFor={props.id}>
					{props.label}
				</label>
			)}
		</div>
	);
}
