import { add, isEqual, isWeekend, startOfDay } from 'date-fns';
import { Dispatch, memo, useContext, useMemo, useRef, useState } from 'react';
import { AnyAction } from 'redux';
import { updateEvent } from '../../../../redux/events';
import { SnackEvent } from '../../../../redux/events/types';
import DayHeader from './DayHeader';
import { cn } from '../../../../lib/functions/utils';
import { areEqual } from 'react-window';
import DayView from '../views/DayView';
import EventsTrack from '../track/Track';
import { getCoordinatesOfEvent } from '../event/utils';
import {
	CALENDAR_TRACK_HEIGHT,
	HOUR_HEIGHT,
} from '../../../../constants/style';
import AmbientTrack from '../track/AmbientTrack';
import { DateContext } from '../../../../context/DateContext';

const Day = memo(
	({
		index,
		style,
		data,
	}: {
		index: number;
		style: any;
		data: {
			itemWidth: number;
			days: Date[];
			selectedDate: Date;
			timestampPosition: number;
			selectDate: (date: Date) => void;
			dispatch: Dispatch<AnyAction>;
			events: (day: Date) => SnackEvent[];
		};
	}) => {
		const today = useContext(DateContext);
		const { selectDate, days, dispatch, timestampPosition, itemWidth, events } =
			data;
		const day = days[index];
		const isAWeekendDay = isWeekend(day);
		const isToday = isEqual(startOfDay(day), startOfDay(today));
		const todayEvents = useMemo(() => events(day), [day]); // useAppSelector(selectEventsByDate(new Date(day)));

		const createEvent = (e: any) => {};
		const onUpdateEvent = (e: SnackEvent) => {
			dispatch(updateEvent(e));
		};

		const { ref: calendarTrackEvent, onCreateEvent } = useCreateEvent(
			day,
			createEvent,
		);

		return (
			<div
				className="snap-start"
				style={style}>
				<div className="flex flex-col h-fit">
					<div
						style={{
							width: itemWidth,
						}}
						className="sticky top-0 bg-white dark:bg-[#121212] z-30 flex flex-col flex-shrink-0 border-b border-zinc-200 dark:border-zinc-400/20 box-content">
						<div className="h-[2.5rem] border-b border-zinc-200 dark:border-zinc-400/20 box-content">
							<DayHeader
								isToday={isToday}
								selectedDate={day}
							/>
						</div>

						<div
							className={cn(
								'w-full border-r border-zinc-200 dark:border-zinc-400/10',
								isAWeekendDay && 'bg-zinc-50 dark:bg-zinc-800/20',
							)}>
							<div className="h-[2rem] flex items-center"></div>
						</div>
					</div>
					<div
						ref={calendarTrackEvent}
						onDoubleClick={onCreateEvent}
						style={{
							width: itemWidth,
						}}
						className={cn(
							'border-r border-zinc-400/20 dark:border-zinc-400/10 relative overflow-x-hidden overflow-y-hidden flex-shrink-0 h-full w-full flex-grow-0 snap-start',
						)}>
						<DayView
							minimal={true}
							selectedDate={day}
							selectDate={selectDate}
							events={todayEvents}
							createEvent={createEvent}
							updateEvent={updateEvent}
							timestampPosition={timestampPosition}
						/>

						<EventsTrack
							height={CALENDAR_TRACK_HEIGHT - HOUR_HEIGHT * 2}
							createEvent={createEvent}
							date={day}
							events={todayEvents}
							updateEvent={updateEvent}
						/>
						<AmbientTrack
							events={[]}
							date={day}
							height={CALENDAR_TRACK_HEIGHT - HOUR_HEIGHT * 2}
						/>
					</div>
				</div>
			</div>
		);
	},
	areEqual,
);

const useCreateEvent = (
	date: Date,
	createEvent: (event: { startTime: Date; endTime: Date }) => void,
) => {
	const ref = useRef(null);

	const [startTimeY, setStartTimeY] = useState<number>(0);

	const onCreateEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Capture the position where the mouse clicked on the track
		// Round of to the nearest 15 minutes and create a new event
		// with the start time at that position, and 30 minutes long
		// which is 40px. 1hr is 80px height.

		const { top, height } = ref.current.getBoundingClientRect();
		const { clientY } = e;
		const y = clientY - top;
		const percentageOfY = y / height;
		const minutes = Math.round(percentageOfY * 60 * 24);
		// round of minutes to closest 5 minute interval
		const startDate = add(date, { minutes: Math.round(minutes / 5) * 5 });
		const coordinates = getCoordinatesOfEvent(
			startDate,
			add(startDate, {
				minutes: 30,
			}),
			height,
		);

		setStartTimeY(coordinates.startY);

		createEvent({
			startTime: startDate,
			endTime: add(startDate, { minutes: 30 }),
		});
	};

	return {
		ref,
		onCreateEvent,
	};
};

export default Day;
