import { addHours, eachHourOfInterval, format } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import { cn } from '../../../../lib/functions/utils';
import { useMemo } from 'react';
import { getSelectedDaysInActualOrder } from '../../lib/days';
import { now } from 'lodash';

const WeekdaysPreview = (props: { selectedDays: string[] }) => {
	const selectedDays = useMemo(
		() => getSelectedDaysInActualOrder(props.selectedDays),
		[props.selectedDays],
	);
	const hoursIntervals = eachHourOfInterval(
		{
			start: addHours(now(), -6),
			end: addHours(now(), 5),
		},
		{
			step: 1,
		},
	);

	const Day = (props: { day: string; isLast?: boolean }) => (
		<div
			className={cn(
				'text-center px-2 border-r py-2 pt-6 border-zinc-400/20 font-semibold text-zinc-500',
				props.isLast && 'border-r-0',
			)}>
			{props.day.slice(0, 3)}
		</div>
	);
	return (
		<div
			style={{
				WebkitMaskImage: 'linear-gradient(0deg,transparent,#000 180px)',
			}}
			className="flex-1 overflow-hidden border-l -my-4 -mr-4 bg-zinc-400/5">
			<div
				className="grid border-b border-zinc-400/20"
				style={{
					gridTemplateColumns: `4rem repeat(${selectedDays.length}, 1fr)`,
				}}>
				<div className="text-center text-xs font-mono px-2 border-r py-2 pt-6 border-zinc-400/20 text-zinc-400">
					GMT+2
				</div>
				<AnimatePresence>
					{selectedDays.map((day, idx) => (
						<Day
							key={`weekday-preview-${day}`}
							day={day}
							isLast={idx === selectedDays.length - 1}
						/>
					))}
				</AnimatePresence>
			</div>
			<div
				className="grid relative pb-2"
				style={{
					gridTemplateColumns: `4rem repeat(${props.selectedDays.length}, 1fr)`,
				}}>
				<div className="text-center relative border-r border-zinc-400/20 h-full grid">
					{hoursIntervals.map((hour) => (
						<div
							style={{ height: '48px' }}
							key={hour.toDateString()}
							className="text-xs font-mono px-2 flex flex-col justify-end py-2">
							<p className="-mb-4 text-zinc-400"> {format(hour, 'HH:mm')}</p>
						</div>
					))}
				</div>
				<AnimatePresence>
					{selectedDays.map((day, _) => (
						<div
							key={`weekday-preview-stretch-${day}`}
							className={cn(
								'text-center relative border-r grid border-zinc-400/20 h-full divide-y divide-zinc-400/10',
								_ === selectedDays.length - 1 && 'border-none',
							)}>
							{hoursIntervals.map(() => (
								<div
									style={{ height: '48px' }}
									key={crypto.randomUUID()}
								/>
							))}
						</div>
					))}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default WeekdaysPreview;
