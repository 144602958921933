import { format, startOfToday } from 'date-fns';
import useToggle from '../../lib/hooks/useToggle';
import { cn } from '../../lib/functions/utils';
import Datepicker, { DatepickerProps } from '../ui/datepicker';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import SFSymbol from '../../assets/icons/SFSymbol';
import { ReactNode } from 'react';

function DatepickerPopup(
	props: DatepickerProps & {
		selectDate: (date?: Date) => void;

		children?: ReactNode;
	},
) {
	const [isOpen, toggle, setIsOpen] = useToggle(false);

	return (
		<Popover
			open={isOpen}
			onOpenChange={setIsOpen}>
			<PopoverTrigger
				className={cn(
					'rounded-lg items-center flex-shrink-0 w-auto dark:text-white px-0 font-normal gap-4 group',
					props.selectedDate && 'text-surface-12',
				)}>
				{!props.children && (
					<>
						<SFSymbol name="calendar" />
						{!props.selectedDate && 'Date'}
						{props.selectedDate && (
							<>
								{format(props.selectedDate, 'dd MMMM yyyy')}
								<SFSymbol
									name={'xmark'}
									onClick={(e) => {
										props.selectDate(undefined);
										e.stopPropagation();
									}}
									className={'w-4 h-4 ml-2 !opacity-0 group-hover:!opacity-100'}
								/>
							</>
						)}
					</>
				)}
				{props.children}
			</PopoverTrigger>
			<PopoverContent>
				<div className="p-2">
					<Datepicker
						{...props}
						value={props.selectedDate ?? startOfToday()}
						onChange={(date) => {
							props.selectDate(date);
							toggle();
						}}
					/>
				</div>
			</PopoverContent>
		</Popover>
	);
}

export default DatepickerPopup;
