import { VideoCameraIcon } from '@heroicons/react/24/solid';
import useColor from '../../../../lib/hooks/useColor';
import { iconColors } from '../../../../constants/style';

function VideoCallButton() {
	const color = useColor(iconColors.labelTertiary);
	return (
		<button className="gap-4 font-medium text-surface-12 dark:text-white">
			<VideoCameraIcon
				style={{ color }}
				className={'w-6 h-6'}
			/>
			Add location or video call
		</button>
	);
}

export default VideoCallButton;
