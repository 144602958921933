import {
	ButtonHTMLAttributes,
	ForwardedRef,
	ReactNode,
	forwardRef,
} from 'react';
import { cn } from '../../../lib/functions/utils';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../tooltip';
import { MotionProps, motion } from 'framer-motion';

// TODO: fix this and have correct props for the button component
type ButtonProps = any & {
	variant?: 'primary' | 'action' | 'accent' | 'danger' | 'warning';
	tooltip?: ReactNode;
};

const Button = forwardRef(
	(props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
		return (
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger>
						<motion.button
							layout="position"
							type="button"
							{...props}
							// Because setting title will trigger the native tooltip to be shown
							className={cn(
								'px-3 rounded-xl py-1 font-medium hover:bg-zinc-200 dark:hover:bg-zinc-400/20 dark:text-zinc-400 dark:hover:text-white hover:text-zinc-900 transition-colors',
								'border-zinc-400/30 dark:border-zinc-400/20',
								props.className,
							)}
							ref={ref}>
							{props.children}
						</motion.button>
					</TooltipTrigger>
					{props.title && <TooltipContent>{props.title}</TooltipContent>}
				</Tooltip>
			</TooltipProvider>
		);
	},
);

export default Button;
