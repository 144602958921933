import { format } from 'date-fns';
import Button from '../../../../components/ui/button';
import {
	ChevronDownIcon,
	ChevronRightIcon,
	PlusCircleIcon,
	PlusIcon,
	VideoCameraIcon,
} from '@heroicons/react/24/solid';
import DatepickerPopup from '../../../../components/picker/DatepickerPopup';
import UserCalendarsDropdown from '../quick-actions/calendars-dropdown';
import UserPreferences from '../../../preferences';
import {
	ChevronLeftIcon,
	MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

const PlannerHeader = (props: {
	date: Date;
	nextDate: () => void;
	prevDate: () => void;
	gotoToday: () => void;
}) => {
	return (
		<div className="flex flex-col px-4 border-b border-zinc-400/20 mb-1 drag">
			<div className="grid grid-cols-3 gap-8 w-full py-2">
				<div className="flex gap-2 items-center">
					<DatepickerPopup
						type="calendar"
						selectedDate={props.date}
						selectDate={() => {}}>
						<Button className="flex items-baseline py-1 px-2 gap-2 rounded-xl hover:bg-zinc-400/30">
							<span className="text-xl font-semibold tracking-tight text-surface-12 dark:text-white">
								{format(props.date, 'MMMM')}
							</span>
							<span className="font-semibold text-surface-12 dark:text-white">
								{format(props.date, 'yyyy')}
							</span>
						</Button>
					</DatepickerPopup>
					<UserCalendarsDropdown />
				</div>
				<Button className="flex-1 max-w-xl border border-zinc-400/30">
					<MagnifyingGlassIcon className="w-5 h-5" />
					<span className="truncate text-ellipsis overflow-hidden">
						Search for events and quick actions...
					</span>
				</Button>
				<div className="flex items-center gap-8 justify-end">
					<div className="flex items-center gap-2">
						<Button
							onClick={props.gotoToday}
							title="Go to today"
							className={'rounded-xl py-1 px-3 font-semibold'}>
							Today
						</Button>
						<Button
							onClick={props.prevDate}
							title="Previous week"
							className={
								'hover:bg-zinc-400/30 dark:hover:bg-white/10 p-1 rounded-lg'
							}>
							<ChevronLeftIcon className="w-5 h-5" />
						</Button>

						<Button
							onClick={props.nextDate}
							title="Next week"
							className={
								'hover:bg-zinc-400/30 dark:hover:bg-white/10 p-1 rounded-lg'
							}>
							<ChevronRightIcon className="w-5 h-5" />
						</Button>
					</div>
					<div className="flex gap-4">
						<Button
							className="bg-teal-600 dark:bg-teal-500/50 px-2 pr-4 h-9 text-white dark:text-white hover:bg-teal-600 hover:text-white"
							title="Schedule a meeting">
							<PlusCircleIcon className="w-6 h-6" />
							New
						</Button>
						<UserPreferences />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlannerHeader;
