import { BrowserRouter } from 'react-router-dom';
import SnackApplicationProvider from './App';
import DailyPlanner from '../features/calendar';

const SnackRouter = () => {
	return (
		<div className="flex flex-col w-screen h-screen bg-white dark:bg-[#121212]">
			<SnackApplicationProvider>
				<BrowserRouter basename="/">
					<DailyPlanner />
				</BrowserRouter>
			</SnackApplicationProvider>
		</div>
	);
};

export default SnackRouter;
