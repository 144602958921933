import { createContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';

export type SnackColor = keyof typeof iconColors;

import {
	addNewCalendar,
	selectCalendars,
	updateSelectedCalendars,
} from '../redux/calendar';
import { iconColors } from '../constants/style';

export type Calendar = {
	id: string;
	name: string;
	color: SnackColor | string;
	hasDateRange: boolean;
	type: 'dynamic' | 'static';
	selectedDays?: string[];
	minDate?: Date;
	maxDate?: Date;
	createdAt: Date;
};

const defaultCalendar: Calendar = {
	id: crypto.randomUUID(),
	name: 'Full Week',
	color: 'primary',
	hasDateRange: false,
	type: 'static',
	createdAt: new Date(),
	selectedDays: [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday',
	],
};

export const CalendarsContext = createContext<{
	calendars: Calendar[];
	selectedCalendar: Calendar | null;
	helpers: {
		addCalendar: (calendar: Calendar) => void;
		removeCalendar: (calendarId: string) => void;
		updateCalendar: (calendar: Calendar) => void;
		onUpdateSelectedCalendars: (selectedCalendars: string[]) => void;
	};
}>({
	calendars: [defaultCalendar],
	selectedCalendar: null,
	helpers: {
		addCalendar: () => {},
		removeCalendar: () => {},
		updateCalendar: () => {},
		onUpdateSelectedCalendars: () => {},
	},
});

const CalendarsProvider = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useAppDispatch();
	const calendars = useAppSelector(selectCalendars);

	const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null>(
		null,
	);

	const addCalendar = (calendar: Calendar) => {
		dispatch(addNewCalendar(calendar));
	};

	const removeCalendar = (calendarId: string) => {};

	const updateCalendar = (calendar: Calendar) => {};

	const onUpdateSelectedCalendars = (selectedCalendars: string[]) => {
		dispatch(updateSelectedCalendars(selectedCalendars));
	};

	useEffect(() => {
		if (!selectedCalendar) {
			setSelectedCalendar(calendars[0]);
		}
	}, [calendars]);

	return (
		<CalendarsContext.Provider
			value={{
				calendars,
				selectedCalendar,
				helpers: {
					addCalendar,
					removeCalendar,
					updateCalendar,
					onUpdateSelectedCalendars,
				},
			}}>
			{children}
		</CalendarsContext.Provider>
	);
};

export default CalendarsProvider;
