import { DragOverlay, useDraggable } from '@dnd-kit/core';
import CalendarEventCard, { CalendarEventCardProps } from '../event';
import { getCoordinatesOfEvent } from '../event/utils';
import useResizableEvent from '../../hooks/useResizableEvent';
import {
	CALENDAR_TRACK_HEIGHT,
	HOUR_HEIGHT,
} from '../../../../constants/style';
import { createPortal } from 'react-dom';
import { useMemo } from 'react';
import { cn } from '../../../../lib/functions/utils';

export default function DraggableEvent(
	props: CalendarEventCardProps & {
		id: string;
	},
) {
	// Get the initial coordinates
	const coords = getCoordinatesOfEvent(
		new Date(props.startTime),
		new Date(props.endTime),
		CALENDAR_TRACK_HEIGHT - HOUR_HEIGHT * 2,
		new Date(props.date),
	);
	// Get the resizable height
	const { height, handleResize } = useResizableEvent(
		coords.endY - coords.startY,
	);

	const { attributes, listeners, setNodeRef, transform, isDragging, over } =
		useDraggable({
			id: props.id,
			data: {
				...props,
				supports: ['droppableDay', 'droppableAllDaySlot'],
			},
		});

	const isOverDay = useMemo(() => {
		if (!over) return false;
		return over.data.current.type === 'droppableDay';
	}, [over]);

	const isOverAllDay = useMemo(() => {
		if (!over) return false;
		return over.data.current.type === 'droppableAllDaySlot';
	}, [over]);

	const isOverCalendar = isOverAllDay || isOverDay;

	return (
		<>
			<div
				ref={setNodeRef}
				{...attributes}
				{...listeners}
				style={{
					top: `${coords.startY}px`,
					height: `${coords.endY - coords.startY}px`,
					marginLeft: 'calc(12px + (100% - 12px) * 0 + 1px)',
					width: 'calc(100% - 12px)',
				}}
				className="absolute w-full px-1">
				<CalendarEventCard
					isDragging={isDragging}
					{...props}
				/>
			</div>

			{isDragging &&
				createPortal(
					<DragOverlay dropAnimation={{ duration: 0.3 }}>
						<div
							className={cn(
								'rounded-xl h-full overflow-hidden shadow transition-transform bg-zinc-300 hover:bg-zinc-300 dark:hover:bg-zinc-800 dark:bg-zinc-800 z-[1000]',
							)}>
							<CalendarEventCard
								isDragging={isDragging}
								{...props}
							/>
						</div>
					</DragOverlay>,
					document.body,
				)}
		</>
	);
}
