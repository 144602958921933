import { useContext } from 'react';
import { CalendarsContext } from '../../context/CalendarsContext';
import { useAppSelector } from '../../redux/store';
import { selectSelectedCalendars } from '../../redux/calendar';

const useCalendars = () => {
	const { calendars, helpers, selectedCalendar } = useContext(CalendarsContext);
	const selectedCalendars = useAppSelector(selectSelectedCalendars);

	return {
		calendars,
		helpers,
		selectedCalendars,
	};
};

export default useCalendars;
