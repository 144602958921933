import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { cn } from '../../../lib/functions/utils';

const Input = forwardRef(
	(
		props: InputHTMLAttributes<HTMLInputElement>,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<input
				ref={ref}
				{...props}
				className={cn(
					'w-full px-4 py-2.5 text-zinc-900 bg-zinc-100 focus:bg-zinc-400/30 dark:focus:bg-zinc-800 dark:bg-zinc-900 dark:text-zinc-100 rounded-lg',
					props.className,
				)}
			/>
		);
	},
);

export default Input;
