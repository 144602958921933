import { useLayoutEffect, useState } from 'react';

export const createFromSFSymbol = async (name: string, color: string) => {
	// If the icon is not in the cache, fetch it and store it in the cache
	const icon = await import(`../../assets/sfsymbols/${name + '-' + color}.svg`);
	return icon;
};

export const useSFSymbol = (name: string, color: string): string => {
	const [imageSrc, setImageSrc] = useState('');

	useLayoutEffect(() => {
		createFromSFSymbol(name, color).then((dataUrl) => {
			const img = new Image();
			img.src = dataUrl; // Preload the image
			img.onload = () => {
				setImageSrc(dataUrl); // Set the image source after it's loaded
			};
		});
	}, [name, color]); // Add dependencies to the effect

	return imageSrc;
};
