import { DndContext } from '@dnd-kit/core';
import '../styles/global.css';
import { Provider } from 'react-redux';
import { ReactNode, useEffect, useMemo } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor, useAppSelector } from '../redux/store';
import { applicationSettings } from '../redux/settings';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'sonner';
import { ToastProvider } from '../context/ToastContext';
import DateContextProvider from '../context/DateContext';
import CalendarsProvider from '../context/CalendarsContext';
import KbdContextProvider from '../context/KbdNavigationContext';

export default function SnackApplicationProvider(props: {
	children: ReactNode;
}) {
	return (
		<div
			className="flex flex-col w-screen h-screen mx-auto overflow-hidden text-base subpixel-antialiased font-normal text-surface-11 dark:text-surface-a11 backdrop-blur"
			id="app-container">
			<title>Tudu ⏲</title>
			<DateContextProvider>
				<Provider store={store}>
					<CalendarsProvider>
						<PersistGate
							loading={null}
							persistor={persistor}>
							<ToastProvider>
								<KbdContextProvider>
									<Toaster />

									<DndContext>{props.children}</DndContext>
								</KbdContextProvider>
							</ToastProvider>
						</PersistGate>
					</CalendarsProvider>
				</Provider>
			</DateContextProvider>
		</div>
	);
}

export const RedirectIfNotOnboarded = ({ children }) => {
	const settings = useAppSelector(applicationSettings);
	const navigate = useNavigate();
	const path = useLocation();

	const isHome = useMemo(() => path.pathname === '/', [path.pathname]);

	useEffect(() => {
		if (!isHome && !settings.onboarded) {
			navigate('/');
		}
	}, [isHome, navigate, settings.onboarded]);

	return children;
};
