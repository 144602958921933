import { EventCardProps } from '../event';
import Modal, { ModalProps } from '../../../../components/ui/modal';
import Textarea from '../../../../components/ui/input/textarea';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { add, endOfDay, format, startOfToday } from 'date-fns';
import DatepickerPopup from '../../../../components/picker/DatepickerPopup';
import { cn } from '../../../../lib/functions/utils';
import Toggle from '../../../../components/ui/switch';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import VideoCallButton from '../meeting/AddVideoCall';
import useEventFunctions from '../../hooks/useEventFunctions';

const EditEventModal = (props: EventCardProps & ModalProps) => {
	const { event, onUpdateEvent } = useEventFunctions(props);
	const now = new Date();

	const form = useFormik({
		initialValues: {
			startTime: new Date(props.startTime),
			endTime: new Date(props.endTime),
			allDay: props.allDay,
			deadline: new Date(props.endTime),
			title: event.title,
			description: event.description,
		},
		onSubmit: (value) => {
			onUpdateEvent({
				title: value.title,
				description: value.description,
				startTime: value.startTime,
				endTime: value.endTime,
				allDay: value.allDay,
				deadline: value.deadline,
			});
			props.onClose();
		},
	});

	const toggleAllDay = () => form.setFieldValue('allDay', !form.values.allDay);

	useEffect(() => {
		if (!form.values.startTime) {
			form.setValues({
				startTime: new Date(props.startTime),
				endTime: new Date(props.endTime),
				allDay: props.allDay,
				deadline: new Date(props.endTime),
				title: event.title,
				description: event.description,
			});
		}
	}, [props]);

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			noPadding>
			<form
				key={props.id}
				onSubmit={form.submitForm}
				className={
					'flex flex-col divide-y divide-zinc-400/20 dark:divide-zinc-400/10 w-full'
				}>
				<div className={'flex flex-col p-4 '}>
					<Textarea
						name={'title'}
						value={form.values.title}
						className={
							'outline-none p-1 text-xl mb-2 font-medium text-surface-12 dark:text-white'
						}
						onChange={(e) => form.setFieldValue('title', e.target.value)}
					/>
					<Textarea
						name={'description'}
						value={form.values.description}
						placeholder={'Add a description'}
						className={'outline-none px-1 dark:text-surface-6'}
						onChange={(e) => form.setFieldValue('description', e.target.value)}
					/>
				</div>
				<div className={'flex flex-col p-4'}>Checklist</div>
				<div className={'flex flex-col p-4'}>
					<VideoCallButton />
				</div>
				<div className={'flex flex-col p-4'}>
					<div className={'flex items-start gap-4 px-1'}>
						<SFSymbol
							name={'clock.fill'}
							className={'mt-1'}
						/>

						<div className={'flex flex-col gap-2'}>
							<DatepickerPopup
								selectDate={(date) => form.setFieldValue('deadline', date)}
								selectedDate={form.values.deadline}>
								<p
									className={cn(
										'hover:text-surface-12 dark:hover:text-white text-base font-medium',
										form.values.allDay && ' text-surface-12 dark:text-white',
									)}>
									{format(
										form.values.deadline ?? startOfToday(),
										'EEE dd MMM yyyy',
									)}
								</p>{' '}
							</DatepickerPopup>
						</div>
					</div>

					<div className={'flex items-center gap-4 pt-2'}>
						<div className={'px-2'}>
							<Toggle
								onChange={() => toggleAllDay()}
								label={'All day'}
							/>
						</div>
						<div className={'px-2'}>Repeat</div>
					</div>
				</div>
				<div className={'flex justify-between gap-4 p-4'}>
					<div className={'flex items-center gap-4'}>Reminder</div>
					<button
						type="submit"
						className={
							'rounded-lg bg-surface-12 text-white dark:bg-white dark:text-surface-12 px-2.5'
						}>
						Save event
					</button>
				</div>
			</form>
		</Modal>
	);
};

export default EditEventModal;
