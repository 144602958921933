export const hexToHSL = (hex: string) => {
	// Convert hex to RGB first
	let r: number, g: number, b: number;
	if (hex.length === 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);
	} else if (hex.length === 7) {
		r = parseInt(hex[1] + hex[2], 16);
		g = parseInt(hex[3] + hex[4], 16);
		b = parseInt(hex[5] + hex[6], 16);
	} else {
		throw new Error('Invalid input string');
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;
	let max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	let h: number,
		s: number,
		l = (max + min) / 2;

	if (max === min) {
		h = s = 0; // achromatic
	} else {
		let d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

	return [h * 360, s * 100, l * 100];
};

export const createLighterBackground = (
	color: string,
	lightness: number = 30,
): string => {
	const [h, s, l] = hexToHSL(color);
	return `hsl(${h}, ${s}%, ${l + lightness}%)`;
};

export const createDarkVariant = (color: string): string => {
	const [h, s, l] = hexToHSL(color);
	return `hsl(${h}, ${s + 10}%, ${l - 30}%)`;
};
