import {
	eachDayOfInterval,
	endOfYear,
	isEqual,
	startOfDay,
	startOfToday,
	startOfYear,
} from 'date-fns';
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { FixedSizeList as List } from 'react-window';
import useTimestampPosition from '../../../lib/hooks/useTimestampPosition';
import useToggle from '../../../lib/hooks/useToggle';

const useCalendarViewEffects = (selectedDate: Date) => {
	const [autoScroll, toggleAutoScroll, setAutoScrollState] = useToggle(true);
	const [days, setDays] = useState(
		eachDayOfInterval(
			{
				start: startOfYear(selectedDate),
				end: endOfYear(selectedDate),
			},
			{
				step: 1,
			},
		),
	);

	const timestampPosition = useTimestampPosition();

	const fixedListOuterRef = useRef<HTMLDivElement>();
	const [timezoneColumn, setTimezoneColumnRef] =
		useState<HTMLDivElement | null>(null);
	const [fixedList, setFixedListRef] = useState<List | null>(null);

	const todayIndex = useMemo(
		() => days.findIndex((day) => isEqual(startOfDay(day), startOfToday())),
		[days],
	);

	const gotoToday = () => {
		{
			if (todayIndex !== 0) {
				fixedList.scrollToItem(todayIndex, 'center');
			}
		}
	};

	useEffect(() => {
		if (fixedList) {
			gotoToday();
		}
	}, [timezoneColumn, fixedList]);

	useEffect(() => {
		const list = fixedListOuterRef.current;

		const handler = (event) => {
			if (!timezoneColumn) return;

			timezoneColumn.scrollTop = event.target.scrollTop;
		};

		if (list) {
			list.addEventListener('scroll', handler);
		}

		return () => list?.removeEventListener('scroll', handler);
	}, [timezoneColumn]);

	useEffect(() => {
		const handler = (event) => {
			const list = fixedListOuterRef.current;

			if (!list) return;

			list.scrollTop = event.target.scrollTop;

			if (timezoneColumn) setAutoScrollState(false);
		};

		if (timezoneColumn) {
			timezoneColumn.addEventListener('scroll', handler);
		}

		return () => timezoneColumn?.removeEventListener('scroll', handler);
	}, [timezoneColumn]);

	useLayoutEffect(() => {
		const scrollToTimestamp = () => {
			if (!timezoneColumn) return;
			const containerHeight = timezoneColumn.scrollHeight;

			const scrollTop = timestampPosition;

			// If user has scrolled, disable autoscrolling to time target
			if (!autoScroll) return;
			timezoneColumn.scrollTop = scrollTop - scrollTop / 2;
		};

		scrollToTimestamp();
	}, [timezoneColumn, timestampPosition]);

	return {
		fixedListOuterRef,
		fixedList,
		days,
		timestampPosition,
		timezoneColumn,
		gotoToday,
		setDays,
		setTimezoneColumnRef,
		setFixedListRef,
	};
};

export default useCalendarViewEffects;
