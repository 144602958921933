import { DayCalendarProps } from '../../types';
import { getDayHourlyInterval } from '../event/utils';
import { cn } from '../../../../lib/functions/utils';
import { isWeekend } from 'date-fns';
import DroppableRow from '../dragndrop/DroppableRow';
import Timestamp from '../time/Timestamp';

export default function DayView(props: DayCalendarProps) {
	const timeIntervals = getDayHourlyInterval(props.selectedDate);

	return (
		<div
			className={cn(
				'grid w-full flex-1 z-0',
				isWeekend(props.selectedDate) && 'bg-zinc-50 dark:bg-zinc-800/20',
			)}>
			<div className="grid flex-auto grid-cols-1 grid-rows-1 h-full relative">
				{timeIntervals.map((time, idx) => (
					<>
						<DroppableRow
							idx={idx}
							time={time}
							key={`time-${time.toString()}-${idx}`}
							isLast={idx === timeIntervals.length}
						/>
					</>
				))}
				<Timestamp
					minimal
					date={props.selectedDate}
					position={props.timestampPosition}
				/>
			</div>
			<DroppableRow
				idx={100}
				time={new Date()}
				key={`time-100-dummy`}
				isLast={true}
			/>
			<DroppableRow
				idx={200}
				time={new Date()}
				key={`time-200-dummy}`}
				isLast={true}
			/>
		</div>
	);
}
