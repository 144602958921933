import { format, isEqual, startOfDay } from 'date-fns';
import useColor from '../../../../lib/hooks/useColor';
import { iconColors } from '../../../../constants/style';
import { cn } from '../../../../lib/functions/utils';

function DayHeader(props: { selectedDate: Date; isToday?: boolean }) {
	const accentColor = useColor(iconColors.primary);
	const { isToday } = props;
	return (
		<div
			className={
				'drag gap-2 px-2 drag bg-white dark:bg-[#121212] h-full w-full relative'
			}>
			{isToday && (
				<div
					className="w-4 h-2 rounded bg-rose-500 inset-x-0 mx-auto mb-1 absolute top-0"
					style={isToday && { background: iconColors.pink }}></div>
			)}
			<button
				className={cn(
					'w-full flex gap-1 items-center text-center justify-center dark:text-white text-zinc-500 p-1',
					isToday && 'font-semibold text-rose-500',
				)}
				style={isToday ? { color: iconColors.pink } : {}}>
				<span>{format(props.selectedDate, 'EEE')} </span>

				<span className={cn('py-1 rounded-lg')}>
					{format(props.selectedDate, 'd')}
				</span>
			</button>
		</div>
	);
}
export default DayHeader;
