import { addDays, addMonths, format, startOfToday } from 'date-fns';
import { useState } from 'react';
import NativeToggle from '../../../../components/ui/input/NativeToggle';
import { AnimatePresence, motion } from 'framer-motion';
import DatepickerPopup from '../../../../components/picker/DatepickerPopup';
import Button from '../../../../components/ui/button';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { iconColors } from '../../../../constants/style';

const DateRange = (props: {
	isEnabled: boolean;
	toggleIsEnabled: () => void;
}) => {
	const [startDate, setStartDate] = useState(startOfToday());
	const [endDate, setEndDate] = useState(addMonths(startOfToday(), 7));

	const onStartDateChange = (date: Date) => {
		setStartDate(date);
		if (endDate < date) {
			setEndDate(addMonths(date, 3));
		}
	};

	const onEndDateChange = (date: Date) => {
		setEndDate(date);
		if (startDate > date) {
			setStartDate(addMonths(date, -3));
		}
	};

	return (
		<div className="space-y-2">
			<div>
				<div className="flex justify-between">
					<label
						htmlFor="date-range-enabled"
						className="font-semibold mb-1">
						Enable date range
					</label>
					<NativeToggle
						id="date-range-enabled"
						name="date-range-enabled"
						value="custom"
						checked={props.isEnabled}
						onChange={props.toggleIsEnabled}
					/>
				</div>
				<p className="text-zinc-500">
					Allows you to define a start and end month for your calendar
				</p>
			</div>
			<AnimatePresence
				mode="wait"
				initial={false}>
				{props.isEnabled && (
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: 'auto' }}
						exit={{ height: 0, opacity: 0 }}
						className="flex items-center justify-between gap-4">
						<DatepickerPopup
							selectDate={onStartDateChange}
							selectedDate={startDate}
							type="picker">
							<div>
								<p className="text-zinc-500 text-start mb-1 font-semibold text-sm">
									Start
								</p>
								<Button className="flex items-baseline py-2 bg-zinc-100 flex-1">
									<span className="font-semibold tracking-tight text-surface-12 dark:text-white">
										{format(startDate, 'dd MMMM')} {format(startDate, 'yyyy')}
									</span>
								</Button>
							</div>
						</DatepickerPopup>

						<p className="text-zinc-500">
							<SFSymbol
								name="arrow.right"
								color={iconColors.gray}
							/>
						</p>

						<DatepickerPopup
							selectDate={onEndDateChange}
							selectedDate={endDate}
							minDate={addDays(startDate, 1)}
							type="picker">
							<div>
								<p className="text-zinc-500 text-start mb-1 font-semibold text-sm">
									End
								</p>
								<Button className="flex items-baseline py-2 bg-zinc-100 flex-1">
									<span className="font-semibold tracking-tight text-surface-12 dark:text-white">
										{format(endDate, 'dd MMMM')} {format(endDate, 'yyyy')}
									</span>
								</Button>
							</div>
						</DatepickerPopup>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default DateRange;
