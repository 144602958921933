import { getTimeZoneNameFromOffset } from '../../../../lib/functions';

const TimezonesHeader = (props: { timezones: Array<any> }) => {
	return (
		<div className="flex left-0 border-b border-zinc-400/20 dark:border-zinc-400/20 h-full">
			{props.timezones.map((_) => (
				<div
					key={`timezone-header-${_}`}
					className={
						'text-sm font-medium px-3 h-[2.5rem] w-[5rem] rounded-md text-zinc-600 dark:text-zinc-500 flex py-2 justify-center'
					}>
					<p className="mt-auto overflow-clip text-ellipsis">
						{getTimeZoneNameFromOffset(-new Date().getTimezoneOffset() / 60)}
					</p>
				</div>
			))}
		</div>
	);
};

export default TimezonesHeader;
