import { RefObject } from 'react';
import { cn } from '../../../../lib/functions/utils';
import { isEqual } from 'lodash';
import { startOfDay, startOfToday } from 'date-fns';
import { motion } from 'framer-motion';

const Timestamp = (props: {
	containerRef?: RefObject<HTMLDivElement>;
	minimal?: boolean;
	position: number;
	date: Date;
}) => {
	const isToday = isEqual(startOfDay(props.date), startOfToday());

	return (
		<motion.div
			className={cn(
				'absolute left-0 transition-transform duration-700 delay-300 z-20 h-[2px]',
				'bg-rose-200 dark:bg-rose-600/20 rounded',
				isToday && 'bg-rose-500 dark:bg-rose-600 h-[2.5px] rounded-full',
			)}
			style={{
				top: `${props.position}px`,
				transform: `translateY(-${1}rem)`,
				width: 'calc(100% + 0.25rem)',
			}}>
			{isToday && (
				<div
					className="h-4 w-[3px] rounded-full absolute bg-rose-500 dark:bg-rose-600"
					style={{ top: '50%', transform: 'translateY(-50%)' }}
				/>
			)}
		</motion.div>
	);
};

export default Timestamp;
