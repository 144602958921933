import { motion } from 'framer-motion';
import { cn } from '../../../../lib/functions/utils';

const TimestampTime = (props: { top: number }) => (
	<motion.button
		layout
		style={{
			top: `calc(${props.top}px - 2rem)`,
			right: 4,
		}}
		className={cn(
			'z-10 ml-auto absolute py-2 rounded text-sm text-number font-semibold font-mono text-right uppercase text-rose-500 dark:text-rose-600',
			'bg-gradient-to-b from-white/20 via-white to-white/20 dark:from-zinc-950/70 dark:via-zinc-950 dark:to-zinc-950/70 ',
		)}>
		{Intl.DateTimeFormat('en-US', {
			hour: '2-digit',
			minute: 'numeric',
			hourCycle: 'h23',
		}).format(new Date())}
	</motion.button>
);

export default TimestampTime;
