import {
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuSeparator,
	ContextMenuShortcut,
	ContextMenuTrigger,
	ContextMenu,
} from '../../../../components/ui/context-menu';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import { Keys, metaKey } from '../../../../components/ui/typography/Kbd';
import { iconColors } from '../../../../constants/style';
import { ReactNode } from 'react';

export default function EventItemContextMenu(props: { children: ReactNode }) {
	return (
		<ContextMenu>
			<ContextMenuTrigger>{props.children}</ContextMenuTrigger>
			<ContextMenuContent className="w-80">
				<ContextMenuItem>
					<SFSymbol
						name={'pencil.line'}
						className={'mr-2'}
					/>
					Edit
					<ContextMenuShortcut>
						{metaKey.mac} {Keys.Shift} {Keys.Enter}
					</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuItem>
					<SFSymbol
						name={'app.badge.checkmark.fill'}
						className={'mr-2'}
					/>
					Mark as complete
					<ContextMenuShortcut>
						{metaKey.mac} {Keys.Shift} {Keys.Enter}
					</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuItem>
					<SFSymbol
						name={'link'}
						className={'mr-2'}
					/>
					Open link
					<ContextMenuShortcut>
						{metaKey.mac} {Keys.Shift} {Keys.Enter}
					</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem>
					<SFSymbol
						name={'calendar.badge.clock'}
						className={'mr-2'}
					/>
					Reschedule
					<ContextMenuShortcut>{metaKey.mac} W</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuItem>
					<SFSymbol
						name={'square.filled.on.square'}
						className={'mr-2'}
					/>
					Duplicate
					<ContextMenuShortcut>{metaKey.mac} D</ContextMenuShortcut>
				</ContextMenuItem>
				<ContextMenuSeparator />
				<ContextMenuItem style={{ color: iconColors.danger }}>
					<SFSymbol
						name={'trash.fill'}
						className={'mr-2'}
						color={iconColors.danger}
					/>
					Delete
					<ContextMenuShortcut style={{ color: iconColors.danger }}>
						{metaKey.mac} -
					</ContextMenuShortcut>
				</ContextMenuItem>
			</ContextMenuContent>
		</ContextMenu>
	);
}
