import { useDroppable } from '@dnd-kit/core';
import { useRef } from 'react';
import { generateUUID } from '../../../../lib/functions';
import { cn } from '../../../../lib/functions/utils';
import { HOUR_HEIGHT } from '../../../../constants/style';

const DroppableRow = (props: { idx: number; time: Date; isLast?: boolean }) => {
	const id = useRef(generateUUID());
	const { setNodeRef, isOver } = useDroppable({
		id: id.current,
		data: {
			type: 'droppableDay',
			time: props.time,
		},
	});

	return (
		<div
			style={{ height: HOUR_HEIGHT }}
			className={cn(
				'grid-item border-b border-zinc-400/10',
				props.isLast && '!border-none',
			)}
			ref={setNodeRef}
		/>
	);
};

export default DroppableRow;
