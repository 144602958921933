import { custom5MinuteCollisions } from '../../../../lib/draggable';
import {
	DndContext,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { MutableRefObject, ReactNode, useRef } from 'react';
import { HOUR_HEIGHT } from '../../../../constants/dimension';
import { createSnapModifier } from '@dnd-kit/modifiers';
import { EventCardProps } from '../event';
import {
	convertCoordinatesToTimeRounded,
	getCoordinatesOfEvent,
} from '../event/utils';
import { add, startOfDay } from 'date-fns';

const snapHeight = HOUR_HEIGHT / 60;
const snapToGridModifier = createSnapModifier(snapHeight);

export default function DailyPlannerDNDContext(props: {
	daysContainerRef: MutableRefObject<HTMLDivElement>;
	children: ReactNode;
	updateEvent: (event: EventCardProps) => void;
}) {
	const { daysContainerRef } = props;
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
	);
	const handleDragEnd = (event: any) => {
		return;
		const { collisions, active } = event;
		const originalCalendarItem = active.data.current;
		originalCalendarItem.startTime = new Date(originalCalendarItem.startTime);
		originalCalendarItem.endTime = new Date(originalCalendarItem.endTime);
		const { trackLength } = originalCalendarItem;
		const daysContainer = daysContainerRef.current;
		let movedToAnotherDay = false;

		if (!daysContainer) {
			return;
		}

		const originalPosition = getCoordinatesOfEvent(
			originalCalendarItem.startTime,
			originalCalendarItem.endTime,
			trackLength,
		);

		const height = originalPosition.endY - originalPosition.startY;
		let newStartPositionY = originalPosition.startY + event.delta.y;
		if (newStartPositionY < 0) newStartPositionY = 0;
		if (newStartPositionY > trackLength) {
			newStartPositionY = 0;
			movedToAnotherDay = true;
		}

		const newStartTime = convertCoordinatesToTimeRounded(
			newStartPositionY,
			trackLength,
			startOfDay(originalCalendarItem.startTime),
		);
		const endTime = convertCoordinatesToTimeRounded(
			newStartPositionY + height,
			trackLength,
			startOfDay(originalCalendarItem.startTime),
		);

		const newCalendarEvent: EventCardProps = {
			...originalCalendarItem,
			startTime: movedToAnotherDay
				? add(newStartTime, { days: 1 })
				: newStartTime,
			endTime:
				endTime.getTime() < newStartTime.getTime()
					? add(endTime, { days: 1 })
					: endTime,
		};

		props.updateEvent(newCalendarEvent);
	};

	return (
		<DndContext
			autoScroll
			sensors={sensors}
			onDragEnd={handleDragEnd}>
			<>{props.children}</>
		</DndContext>
	);
}
