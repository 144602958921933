import { EventCardProps } from '../event';
import DraggableEvent from '../dragndrop/DraggableEvent';

type EventTrackProps = {
	date: Date;
	events: EventCardProps[];
	height: number;
	updateEvent: (event: EventCardProps) => void;
	createEvent: (event: { startTime: Date; endTime: Date }) => void;
};

const EventsTrack = (props: EventTrackProps) => {
	return (
		<>
			{props.events.map((event, idx) => (
				<DraggableEvent
					key={`event-travk-${idx}`}
					trackLength={props.height}
					{...event}
					updateEvent={props.updateEvent}
					date={props.date}
				/>
			))}
		</>
	);
};

export default EventsTrack;
