import { EventCardProps } from '../event';
import SFSymbol from '../../../../assets/icons/SFSymbol';
import {
	CALENDAR_TRACK_HEIGHT,
	HOUR_HEIGHT,
	iconColors,
} from '../../../../constants/style';
import useResizableEvent from '../../hooks/useResizableEvent';
import DraggableEvent from '../dragndrop/DraggableEvent';
import { getCoordinatesOfEvent } from '../event/utils';

type EventTrackProps = {
	date: Date;
	events: EventCardProps[];
	height: number;
};

const AmbientTrack = (props: EventTrackProps) => {
	// Get the initial coordinates

	return (
		<>
			{props.events.map((event, idx) => (
				<AmbientEvent
					event={event}
					height={props.height}
					key={event.id + event.taskId}
					date={props.date}
				/>
			))}
		</>
	);
};

const AmbientEvent = (props: {
	event: EventCardProps;
	height: number;
	date: Date;
}) => {
	const coords = getCoordinatesOfEvent(
		new Date(props.event.startTime),
		new Date(props.event.endTime),
		CALENDAR_TRACK_HEIGHT - HOUR_HEIGHT * 2,
		new Date(props.date),
	);
	// Get the resizable height
	const { height, handleResize } = useResizableEvent(
		coords.endY - coords.startY,
	);

	return (
		<div
			style={{
				top: coords.startY,
				height,
			}}
			className="absolute left-0 w-4 rounded-full bg-green-500 py-1">
			<SFSymbol
				name={'figure.cooldown'}
				className="w-4 h-4"
				color={iconColors.white}
			/>
		</div>
	);
};

export default AmbientTrack;
