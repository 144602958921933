import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Keys } from '../components/ui/typography/Kbd';

export type KeyboardEventListener = {
	key: string | Keys;
	auxillary?: Array<string | Keys>;
	label?: string;
	callback: (e: KeyboardEvent) => void;
};

type KbdContextType = {
	registerListeners: (listeners: KeyboardEventListener[]) => boolean;
	unregisterListeners: (listeners: KeyboardEventListener[]) => void;
	listeners: KeyboardEventListener[];
	registeredListeners: Map<
		string,
		{
			label: string;
			auxillary?: Array<string | Keys>;
		}
	>;
};

export const KbdContext = createContext<KbdContextType>({
	registerListeners: (evt) => {
		return true;
	},
	unregisterListeners: () => {},
	listeners: [],
	registeredListeners: new Map(),
});

export default function KbdContextProvider({ children }) {
	const [listeners, setListeners] = useState<
		Map<string, (e: KeyboardEvent) => void>
	>(new Map());
	const [registeredListeners, setRegisteredEvents] = useState<
		Map<string, { label: string; auxillary?: Array<string | Keys> }>
	>(new Map());

	const registerListeners = useCallback(
		(newListeners: KeyboardEventListener[]) => {
			newListeners.forEach((listener) => {
				const callback = (event: KeyboardEvent) => {
					if (event.key === listener.key) {
						listener.callback(event);
					}
				};

				listeners.set(listener.key as string, callback);
				window.addEventListener('keydown', callback);
				setRegisteredEvents((events) => {
					events.set(listener.key as string, {
						label: listener.label,
						auxillary: listener.auxillary,
					});
					return events;
				});
			});
			setListeners(new Map(listeners));

			return true;
		},
		[],
	);

	const unregisterListeners = useCallback(
		(newListeners: KeyboardEventListener[]) => {
			newListeners.forEach((listener) => {
				const callback = listeners.get(listener.key as string);
				if (callback) {
					window.removeEventListener('keydown', callback);
					listeners.delete(listener.key as string);
					setRegisteredEvents((events) => {
						events.delete(listener.key as string);
						return events;
					});
				}
			});
			setListeners(new Map(listeners));
		},
		[],
	);

	const value = {
		registerListeners,
		unregisterListeners,
		listeners: Array.from(listeners.keys()).map((key) => ({
			key,
			callback: listeners.get(key)!,
			auxillary: [],
		})),
		registeredListeners,
	};

	return <KbdContext.Provider value={value}>{children}</KbdContext.Provider>;
}

/** Hooks */

export const useKeyboardListeners = () => {
	const {
		registerListeners,
		unregisterListeners,
		listeners,
		registeredListeners,
	} = useContext(KbdContext);

	return {
		registerListeners,
		unregisterListeners,
		listeners,
		registeredListeners,
	};
};

export const useKeyboardShortcuts = (
	shortcuts: Array<KeyboardEventListener>,
) => {
	const { registerListeners, unregisterListeners } = useKeyboardListeners();

	const registeredShortcuts = useMemo(() => shortcuts, [shortcuts]);

	useEffect(() => {
		registerListeners(registeredShortcuts);
		return () => unregisterListeners(registeredShortcuts);
	}, [registeredShortcuts]);
};
