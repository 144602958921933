import { useCallback, useMemo } from 'react';
import { selectEventById, updateEvent } from '../../../redux/events';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { selectTaskById, updateTask } from '../../../redux/tasks';
import { SnackEvent } from '../../../redux/events/types';

const useEditEvent = (event: SnackEvent) => {
	const dispatch = useAppDispatch();
	const task = useAppSelector(selectTaskById(event?.taskId));

	const { title, description } = useMemo(() => {
		if (task) {
			return {
				title: task.title,
				description: task.description,
			};
		}

		return {
			title: event.title,
			description: event.description,
		};
	}, [event, task]);

	const detachEventFromTask = useCallback(() => {
		dispatch(
			updateEvent({
				...event,
				title: title,
				description: description,
				taskId: '',
			}),
		);
	}, [event]);

	const onTitleChanged = useCallback(
		(e) => {
			if (task) {
				dispatch(
					updateTask({
						...task,
						title: e.target.value,
					}),
				);
				return;
			}

			dispatch(
				updateEvent({
					...event,
					title: e.target.value,
				}),
			);
		},
		[task],
	);

	const onDescriptionChanged = useCallback(
		(e) => {
			if (task) {
				dispatch(
					updateTask({
						...task,
						description: e.target.value,
					}),
				);
				return;
			}

			dispatch(
				updateEvent({
					...event,
					description: e.target.value,
				}),
			);
		},
		[task],
	);

	const onUpdateEvent = useCallback(
		(data: {
			startTime: Date;
			endTime: Date;
			allDay: boolean;
			deadline: Date;
			title: string;
			description: string;
		}) => {
			if (task) {
				dispatch(
					updateTask({
						...task,
						title: data.title,
						description: data.description,
						deadline: data.deadline,
					}),
				);
				return;
			}

			dispatch(
				updateEvent({
					...event,
					...data,
				}),
			);
		},
		[event, task, dispatch],
	);

	return {
		task,
		event: {
			...event,
			title,
			description,
		},
		detachEventFromTask,
		onTitleChanged,
		onDescriptionChanged,
		onUpdateEvent,
	};
};

export default useEditEvent;
