import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import {
	format,
	getDay,
	isAfter,
	isBefore,
	isEqual,
	isSameMonth,
	isSameWeek,
	isToday,
	startOfToday,
} from 'date-fns';
import { useMemo } from 'react';
import useCalendarDates from '../../../lib/hooks/useCalendarDates';
import Button from '../button';
import { cn } from '../../../lib/functions/utils';
import { AnimatePresence, motion } from 'framer-motion';

type Day = {
	date: string;
	isCurrentMonth?: boolean;
	isToday?: boolean;
};

export type DatepickerProps = {
	value?: Date;
	onChange?: (date: Date) => void;
	type?: 'calendar' | 'picker';
	selectedDate?: Date;
	minDate?: Date;
	maxDate?: Date;
};

const colStartClasses = [
	'',
	'col-start-2',
	'col-start-3',
	'col-start-4',
	'col-start-5',
	'col-start-6',
];

export default function Datepicker(props: DatepickerProps) {
	let today = useMemo(() => props.value ?? startOfToday(), [props.value]);
	const { prevMonth, nextMonth, month, firstDayOfCurrentMonth, selectedDate } =
		useCalendarDates(today);

	const selectDate = (date: Date) => {
		props.onChange(date);
	};

	const prevMonthDisabled =
		props.minDate && isBefore(firstDayOfCurrentMonth, props.minDate);

	const nextMonthDisabled =
		props.maxDate && isAfter(firstDayOfCurrentMonth, props.maxDate);

	const { minDate, maxDate } = props;
	console.log({ minDate, maxDate });

	return (
		<motion.div className="w-full">
			<div className="flex items-center justify-between px-2 text-center text-zinc-900">
				<Button
					type="button"
					onClick={prevMonth}
					disabled={prevMonthDisabled}
					className={cn(
						'flex flex-none items-center justify-center p-1.5 rounded-xl bg-white dark:bg-surface-12 bg-opacity-90 text-zinc-900 dark:text-white',
						'disabled:cursor-not-allowed disabled:opacity-10',
					)}>
					<span className="sr-only">Previous month</span>
					<ChevronLeftIcon
						className="w-5 h-5"
						aria-hidden="true"
					/>
				</Button>
				<p className="font-semibold dark:text-white">
					{format(firstDayOfCurrentMonth, 'MMMM yyyy')}
				</p>
				<Button
					type="button"
					onClick={nextMonth}
					disabled={nextMonthDisabled}
					className={cn(
						'flex flex-none items-center justify-center p-1.5 rounded-xl bg-white dark:bg-surface-12 bg-opacity-90 text-zinc-900 dark:text-white',
						'disabled:cursor-not-allowed disabled:opacity-10',
					)}>
					<span className="sr-only">Next month</span>
					<ChevronRightIcon
						className="w-5 h-5"
						aria-hidden="true"
					/>
				</Button>
			</div>
			<div className="grid grid-cols-7 mt-6 text-xs leading-6 text-center uppercase text-zinc-500">
				<div>S</div>
				<div>M</div>
				<div>T</div>
				<div>W</div>
				<div>T</div>
				<div>F</div>
				<div>S</div>
			</div>
			<div className="grid grid-cols-7 gap-px mt-2 text-sm uppercase rounded-xl isolate">
				{month.map((day, dayIdx) => (
					<motion.button
						initial={{ opacity: 0, right: -10 }}
						animate={{ opacity: 1, left: 0 }}
						exit={{ opacity: 0, left: -10 }}
						key={day.toString()}
						type="button"
						onClick={() => selectDate(day)}
						className={cn(
							'py-1 focus:z-10 rounded-lg flex items-center justify-center text-number flex-col gap-1 transition-all text-zinc-400 dark:text-zinc-500',
							'hover:bg-zinc-400/20',
							dayIdx === 0 && 'rounded-tl-lg',
							dayIdx === 6 && 'rounded-tr-lg',
							dayIdx === month.length - 7 && 'rounded-bl-lg',
							dayIdx === month.length - 1 && 'rounded-br-lg',
							dayIdx === 0 && colStartClasses[getDay(day)],
							isSameMonth(day, firstDayOfCurrentMonth) &&
								'text-zinc-900 dark:text-surface-2',
							isSameWeek(day, today) &&
								props.type === 'calendar' &&
								'bg-zinc-400/30 dark:bg-zinc-400/20 text-zinc-900',
							isEqual(day, selectedDate) &&
								props.type !== 'calendar' &&
								'font-semibold text-zinc-900 dark:text-white bg-zinc-400/50',
							isToday(day) &&
								'bg-rose-500 text-white font-semibold hover:bg-rose-500',
							isEqual(day, selectedDate) &&
								'bg-zinc-900 text-white font-semibold hover:bg-zinc-900 dark:bg-white dark:text-zinc-900 dark:hover:bg-white',
							props.minDate &&
								isBefore(day, props.minDate) &&
								'cursor-not-allowed opacity-10',
							props.maxDate &&
								isAfter(day, props.maxDate) &&
								'cursor-not-allowed opacity-10',
						)}>
						<time
							dateTime={day.getDate().toString()}
							className={clsx(
								'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
								// isEqual(day, selectedDate) && isToday(day) && 'bg-indigo-600',
								// isEqual(day, selectedDate) && !isToday(day) && 'bg-zinc-900',
							)}>
							{format(day, 'd')}
						</time>
					</motion.button>
				))}
			</div>
		</motion.div>
	);
}
