import { formatTime } from '../../../../lib/functions/utils';
import clsx from 'clsx';
import { getCoordinatesOfEvent } from './utils';
import useResizableEvent from '../../hooks/useResizableEvent';
import { useDraggable } from '@dnd-kit/core';
import { SnackEvent } from '../../../../redux/events/types';
import { useAppSelector } from '../../../../redux/store';
import { selectTaskById } from '../../../../redux/tasks';
import { iconColors } from '../../../../constants/style';
import { differenceInMinutes } from 'date-fns';
import EventItemContextMenu from './EventContextMenu';
import useToggle from '../../../../lib/hooks/useToggle';
import EditEventModal from '../modals/EditEventModal';
import useColor from '../../../../lib/hooks/useColor';
import {
	createDarkVariant,
	createLighterBackground,
} from '../../../../lib/styles';

export type EventCardProps = SnackEvent;

const CalendarEventCardContent = (
	props: EventCardProps & { title: string; duration: number },
) => {
	return (
		<div
			className={clsx(
				'flex flex-col px-2 pl-4 overflow-y-auto text-sm leading-5 h-full relative z-[1] rounded-lg',
			)}>
			<p
				style={{
					fontSize:
						props.duration > 15
							? 'clamp(10px, 13px, 13px)'
							: 'clamp(10px, 10px, 13px)',
				}}
				className={clsx(
					`order-1 capitalize text-balance font-semibold text-base`,
				)}>
				{props.title}
			</p>
			{props.duration > 15 && (
				<p className={clsx('uppercase line-clamp-1 font-mono text-xs')}>
					<time dateTime={props.startTime.toString()}>
						{formatTime(props.startTime)}
					</time>{' '}
					-{' '}
					<time dateTime={props.endTime.toString()}>
						{formatTime(props.endTime)}
					</time>
				</p>
			)}
		</div>
	);
};

export type CalendarEventCardProps = EventCardProps & {
	trackLength: number;
	id: string;
	updateEvent: (event: EventCardProps) => void;
	date: Date;
	isDragging?: boolean;
};

const CalendarEventCard = (props: CalendarEventCardProps) => {
	const task = useAppSelector(selectTaskById(props.taskId));
	const [showEditEvent, toggleShowEditEvent] = useToggle(false);

	const color = useColor(iconColors.warning);
	const hsl = createLighterBackground(color, props.isDragging ? 60 : 40);
	const darkerHSL = createDarkVariant(color);

	const duration = differenceInMinutes(
		new Date(props.endTime),
		new Date(props.startTime),
	);

	return (
		<EventItemContextMenu>
			<EditEventModal
				{...props}
				isOpen={showEditEvent}
				onClose={toggleShowEditEvent}
			/>
			<div
				onClick={toggleShowEditEvent}
				data-id={`event-${props.startTime.toString()}`}
				style={{
					paddingTop: duration > 30 ? '0.5rem' : '0',
				}}
				className={clsx(
					'h-full w-full relative group cursor-pointer dark:group-hover:bg-purple-800/50 text-purple-800 bg-purple-100 dark:text-purple-200 dark:bg-purple-900',
					'overflow-hidden grid-col relative grid h-full w-full grid-rows-1 truncate transition-colors',
					'rounded-md z-10 group-hover:text-purple-800 group-hover:bg-purple-200/70 dark:group-hover:text-purple-200',
					props.isDragging && 'group-hover:bg-opacity-50',
				)}>
				<div className="pointer-events-none absolute left-0 top-0 flex h-full items-center py-1 pl-1">
					<span
						className="flex w-[3px] shrink-0 rounded bg-purple-600 dark:bg-purple-200"
						style={{ height: 'max(6px, 100%)' }}
					/>
				</div>
				<CalendarEventCardContent
					{...props}
					color={color}
					title={task?.title ?? props.title}
					duration={duration}
				/>
			</div>
		</EventItemContextMenu>
	);
};

export default CalendarEventCard;
