import Button from '../../components/ui/button';
import Modal from '../../components/ui/modal';
import useToggle from '../../lib/hooks/useToggle';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';

export default function UserPreferences() {
	const [isPreferencesModalOpen, toggleIsPreferencesModalOpen] =
		useToggle(false);
	return (
		<>
			<Modal
				size="xl"
				isOpen={isPreferencesModalOpen}
				onClose={toggleIsPreferencesModalOpen}>
				<div className="flex flex-col items-center justify-center">
					<p>Preferences</p>
				</div>
			</Modal>
			<Button
				className="rounded-full bg-zinc-300/20 dark:bg-zinc-300/10 p-1"
				onClick={toggleIsPreferencesModalOpen}>
				<EllipsisHorizontalIcon className="w-6 h-6" />
			</Button>
		</>
	);
}
