import { ForwardedRef, InputHTMLAttributes, forwardRef, useId } from 'react';
import { cn } from '../../../lib/functions/utils';

const NativeToggle = forwardRef(
	(
		props: InputHTMLAttributes<HTMLInputElement>,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<>
				<input
					{...props}
					ref={ref}
					style={{
						display: 'none',
					}}
					type="checkbox"
					className={cn('hidden')}
					id={`cb-toggle`}
				/>
				<label
					htmlFor={`cb-toggle`}
					className="native-toggle "></label>
			</>
		);
	},
);

export default NativeToggle;
