import { createSlice } from '@reduxjs/toolkit';
import { SnackGlobalCalendar } from './types';
import { RootState } from '../store';
import { startOfToday } from 'date-fns';
import { CalendarView } from '../../lib/types';

const initialState: SnackGlobalCalendar = {
	items: [],
	preferences: {
		view: CalendarView.Week,
		width: 1290,
	},
	selectedCalendars: [],
	selectedDate: startOfToday(),
};

export const calendarSlice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		seedGCCalendars: (state, action) => {
			const items = action.payload;
			state.items = Array.from(new Set([...state.items, ...items]));
		},
		addNewCalendar: (state, action) => {
			state.items.push(action.payload);
		},
		updateSelectedCalendars: (state, action) => {
			state.selectedCalendars = action.payload;
		},
		deleteCalendar: (state, action) => {
			state.items = state.items.filter(
				(calendar) => calendar.id !== action.payload,
			);
		},
		updateCalendar: (state, action) => {
			const { id, title, color, emoji } = action.payload;
			const existingCalendar = state.items.find(
				(calendar) => calendar.id === id,
			);
			if (existingCalendar) {
				existingCalendar.name = title;
				existingCalendar.color = color;
				existingCalendar.createdAt = new Date();
			}
		},
		updateSelectedDate: (state, action) => {
			state.selectedDate = action.payload;
		},
		updatePreferences: (state, action) => {
			const { view, width, expanded } = action.payload;
			state.preferences.view = view;
			state.preferences.width = width;
			state.preferences.expanded = expanded;
		},
	},
});

export const {
	addNewCalendar,
	deleteCalendar,
	updateCalendar,
	updatePreferences,
	updateSelectedDate,
	seedGCCalendars,
	updateSelectedCalendars,
} = calendarSlice.actions;

export default calendarSlice.reducer;

/** Selectors */

export const selectCalendars = (state: RootState) => state.calendars.items;
export const selectSelectedCalendars = (state: RootState) =>
	state.calendars.selectedCalendars;
