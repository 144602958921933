export const LAYOUT = {
	CALENDAR_TOP_MARGIN: 24,
	CALENDAR_DRAGGABLE_HEIGHT: 36,
	CALENDAR_ACTIONS_HEIGHT: 44,
	CALENDAR_DAYS_HEIGHT: 56,
	CALENDAR_PADDING: 12,
	NAVBAR_HEIGHT: 36,
	MAX_WIDTH: 720,
	SIDEBAR_WIDTH: '16rem',
};

export const HOUR_HEIGHT = 80; // 80px per hour
